<template>
  <div>
    <div v-for="region in regions" :key="region.id">
      <CLink
          @click="onClick(region)"
          class="region-item"
          :class="{'active': region.id===selected}"
      >
        <div class="d-flex justify-content-between">
          <h3 class="region-item__title">
            {{ region.name }}
          </h3>
          <CIcon name="cipCircleCheckFill" size="custom-size" width="18" height="18" class="ml-2 text-primary"
                 v-if="region.id===selected"/>
          <CIcon name="cipCircle" size="custom-size" width="18" height="18" class="ml-2" v-else/>
        </div>
      </CLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegionSelector",
  emits: ['update:value', 'input'],
  props: {
    regions: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Number,
      default: null
    }
  },

  methods: {
    onClick(region) {
      this.$emit('update:value', region.id)
      this.$emit('input', region)
    }
  }
}
</script>