<template>
  <CModal :show.sync="show" :title="$pgettext('event.modal.create_new_event.title', 'New Event')" centered :closeOnBackdrop="canClose">
    <PComponentLoader :loading="loading"/>

    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipPlus" size="custom-size" width="36" height="36" class="mr-3 icon-circle icon-circle--primary"/>
          <div>
            <h5 class="modal-title" v-translate translate-context="event.modal.create_new_event.title">New Event</h5>
            <div>{{stepDescription}}</div>
          </div>
        </div>
      </div>
      <CButtonClose @click="show = false" v-if="canClose"/>
    </template>

    <template v-if="!loading">
      <template v-if="!region">
        <!-- Step 1 - Select region ( if not already selected ) -->
        <RegionSelector
            :regions="regions"
            :selected="regionId"
            @input="chooseRegion"
        />
      </template>
      <template v-else-if="!contractSelected">
        <!-- Step 2 - Select contract/base plan -->
        <template v-if="contracts && contracts.length">
          <ContractSelector
              :contracts="contracts"
              :selected="contractId"
              @input="chooseContract"
          />
        </template>
        <template v-else-if="categories && categories.length">
          <BasePlanSelector
              v-if="region"
              :region="region"
              :categories="categories"
              :selected="basePlanId"
              @input="chooseBasePlan"
          />
        </template>
        <template v-else>
          Contract Us!
        </template>
      </template>
      <template v-else-if="contractSelected">
        <!-- Step 3 - Fill base minimum for event creation -->
        <PFRow size="12x12">
          <template #title>
            <label class="font-weight-medium mb-2">{{ $pgettext('event.modal.create_new_event.label', 'Event Name') }}</label>
          </template>
          <CInput v-model="v$.form.name.$model"
                  addWrapperClasses="has-counter"
                  :maxlength="v$.form.name.maxLength.$params.max"
                  :placeholder="$pgettext('event.modal.create_new_event.placeholder','Event Name')">
            <template v-slot:description>
              <div class="counter">{{
                  v$.form.name.$model ? v$.form.name.$model.length : 0
                }}/{{ v$.form.name.maxLength.$params.max }}
              </div>
            </template>
          </CInput>
          <div class="text-help-dark text-help-dark--sm" style="margin-top: -8px" v-translate translate-context="event.modal.create_new_event.help">You can change it later</div>
        </PFRow>
      </template>
    </template>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate v-if="canClose">Close</CButton>
      <PButton
          color="primary"
          class="mr-3"
          :disabled="buttonDisabled"
          :loading="creating"
          @click="moveToNextStep"
      >{{ buttonLabel }}
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import RegionSelector from "@/domain/events/components/RegionSelector.vue";
import BasePlanSelector from "@/domain/events/components/BasePlanSelector.vue";
import ContractSelector from "@/domain/events/components/ContractSelector.vue";
import timeZone from "@/domain/core/utils/timeZone";
import {useVuelidate} from '@vuelidate/core'
import {maxLength, minLength, required} from '@vuelidate/validators'
const  steps = {
  REGION: 'Select region',
  CONTRACT: 'Select contract',
  BASE_PLAN: 'Select base plan',
  EVENT: 'Select new event'
}
export default {
  name: "CreateNewEventModal",
  components: {
    ContractSelector,
    BasePlanSelector,
    RegionSelector,
    PComponentLoader,
    PButton,
    PFRow
  },
  data() {
    return {
      show: false,
      loading: true,
      creating: false,
      form: {
        name: null,
        contract: null,
        base_plan: null,
        timezone: null
      },
      selectedRegion: null,
      selectedContract: null,
      selectedBasePlan: null,
    }
  },
  props: {
    canClose: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('events', [
      'regions',
      'region',
      'contracts',
      'categories',
      'urlSessionParams'
    ]),
    step() {
      if (!this.region) return steps.REGION
      if (!this.contractSelected && this.contracts && this.contracts.length) return steps.CONTRACT
      if (!this.contractSelected && this.categories && this.categories.length) return steps.BASE_PLAN
      return steps.EVENT
    },
    buttonDisabled() {
      if (this.step === steps.REGION) return !this.selectedRegion
      if (this.step === steps.CONTRACT) return !this.selectedContract
      if (this.step === steps.BASE_PLAN) return !this.selectedBasePlan
      if (this.step === steps.EVENT) return !this.isFormReady
      return true
    },
    buttonLabel() {
      if (this.step === steps.EVENT) return this.$pgettext('event.modal.create_new_event.actions', 'Create')
      return this.$pgettext('event.modal.create_new_event.actions', 'Next')
    },
    regionId() {
      return this.selectedRegion ? parseInt(this.selectedRegion.id) : null
    },
    contractId() {
      return this.selectedContract ? parseInt(this.selectedContract.id) : null
    },
    basePlanId() {
      return this.selectedBasePlan ? parseInt(this.selectedBasePlan.id) : null
    },
    isFormReady() {
      if (!this.contractSelected) return false
      if (this.v$.$invalid) return false
      return true
    },
    contractSelected() {
      return this.form.contract !== null || this.form.base_plan !== null
    },
    stepDescription() {
      if (!this.region) return this.$pgettext('event.modal.create_new_event.step_description', 'Select region')
      if (!this.contractSelected && this.contracts && this.contracts.length) return this.$pgettext('event.modal.create_new_event.step_description', 'Select contract')
      if (!this.contractSelected && this.categories && this.categories.length) return this.$pgettext('event.modal.create_new_event.step_description', 'Distribute photos at your events')
      return this.$pgettext('event.modal.create_new_event.step_description', 'Create new event')
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        name: {required,minLength: minLength(3), maxLength: maxLength(this.hasPerm('advanced.bypass_char_limits') ? 255 : 44)},
      }
    }
  },
  methods: {
    ...mapActions('events', [
      'fetchCreateConfig',
      'fetchCategories',
      'setRegion',
      'createNewEvent'
    ]),
    async open() {
      this.show = true
      this.loading = true

      this.selectedRegion = null
      this.selectedContract = null
      this.selectedBasePlan = null
      this.creating = false

      await this.fetchCreateConfig()
      this.form = {
        name: null,
        contract: null,
        base_plan: null,
        timezone: timeZone.getBrowserDefault()
      }
      if (this.urlSessionParams) {
        if (!this.regionId && this.urlSessionParams.region && this.regions) {
          const region = this.regions.find(r => r.id === parseInt(this.urlSessionParams.region))
          if (region) {
            this.selectedRegion = region
            await this.onSelectRegion()
          }
        }
        if (this.urlSessionParams.plan) {
          // We need to check if the plan is available in the region
          this.categories.forEach(c => {
            const plan = c.base_plans.find(p => p.id === parseInt(this.urlSessionParams.plan))
            if (plan) {
              this.selectedBasePlan = plan
              this.onSelectPlan()
            }
          })
        }
      }
      this.loading = false
    },
    async chooseRegion(region) {
      this.selectedRegion = region
    },
    async onSelectRegion() {
      this.loading = true
      await this.setRegion(this.selectedRegion)
      await this.fetchCategories()
      this.loading = false
    },
    async chooseBasePlan(basePlan) {
      this.selectedBasePlan = basePlan
    },
    async onSelectPlan() {
      this.form.base_plan = this.selectedBasePlan.id
    },
    async chooseContract(contract) {
      this.selectedContract = contract
    },
    async onChangeContract() {
      this.form.contract = this.selectedContract.id
    },
    async create() {
      this.creating = true
      try {
        this.form.timezone = timeZone.getBrowserDefault()
        const item = await this.createNewEvent(this.form)
        await this.$router.push({name: 'eventWizard.date_and_name', params: {picaServiceId: item['id']}})
        this.show = false
      } catch (e) {
        this.creating = false
        await this.notifyMessage({text: e.message, messageType: 'danger'})
      }
    },
    moveToNextStep() {
      if (this.step === steps.REGION) {
        this.onSelectRegion()
      } else if (this.step === steps.CONTRACT) {
        this.onChangeContract(this.selectedContract)
      } else if (this.step === steps.BASE_PLAN) {
        this.onSelectPlan()
      } else if (this.step === steps.EVENT) {
        this.create()
      }
    }
  },
}
</script>