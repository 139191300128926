<template>
  <div>
    <ViewLoadingMessage :loading="firstLoading"/>
    <CreateNewEventModal ref="createNewEventModal" :canClose="!showWelcomeToPica"/>
    <EventTutorial ref="eventTutorial" @completed="onTutorialCompleted"
                   v-if="showWelcomeToPica"/>

    <WelcomeToPica ref="welcomeToPica" @create="openCreateNewEvent"
                   v-if="showWelcomeToPica"/>

    <CRow v-else-if="!firstLoading">
      <CCol lg="12">
        <CCard class="no-borders">
          <CCardHeader>
            <div class="d-flex align-items-center">
              <h1 class="main-header mr-4" v-translate>Events</h1>
              <template v-if="filtersApplied">
                <span class="mr-4" v-translate>Found %{ totalResults } results</span>
                <CButton @click="clearFilters" color="secondary" size="sm" class="mr-4" v-translate>
                  Cancel
                </CButton>
              </template>

            </div>
            <div class="card-header-actions">
              <CButton @click.prevent="openCreateNewEvent" v-if="hasPerm('events.create')" color="primary">
                <CIcon name="cip-plus" style="vertical-align: sub"/>
                <translate>New event</translate>
              </CButton>
            </div>
          </CCardHeader>

          <CCardBody>
            <CDataTable
                class="custom-table"
                :items="picaServices"
                :fields="fields"
                :hover="false"
                :striped="true"
                :bordered="true"
                :small="false"
                :fixed="false"
                :loading="loading"
                :no-items-view="{ noResults: this.$gettext('no filtering results available'), noItems: this.$gettext('No events found') }"
                :column-filter="{ external: true, lazy: true }"
                :column-filter-value="columnFilterValue"
                @update:column-filter-value="filterResults($event)"
                v-if="picaServices"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No events found</h5>
                </div>
              </template>
              <template #event_name="{ item }">
                <th>
                  <CLink :to="routeToDetail(item)" style="color: #100F23">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <CIcon name="cipDot"
                               size="custom-size"
                               :height="10"
                               class="text-danger mr-2"
                               v-c-tooltip="{placement:'top-center', content:$gettext('closed'), appendToBody:true}"
                               v-if="resortIsClosed(item)"/>

                        <CIcon name="cip-globe" class="text-primary v-align-sub mr-2" width="20"
                               v-if="item.event_owner && item.event_owner.third_level_domain"/>
                      </div>
                      <div class="font-weight-semi-bold">{{ item.event.name }}</div>
                    </div>
                  </CLink>
                </th>
              </template>

              <template #event_start_at="{ item }">
                <td>
                  <template v-if="item.event.start_at">
                    {{ item.event.start_at|formatDate }}
                    <div class="tz" v-if="hasDifferentTimezone(item.event.timezone)">
                      {{ item.event.timezone }}
                    </div>
                  </template>
                </td>
              </template>
              <template #status="{ item }">
                <td>
                  <CBadge class="badge-outline" color="light">
                    <CIcon name="cipDot" size="custom-size" :height="6" style="vertical-align: middle"
                           :class="item.event.is_draft?'text-warning':'text-success'"/>
                    <span class="ml-1">{{
                        item.event.is_draft ? $gettext('Draft') : $gettext('Active')
                      }}</span>
                  </CBadge>
                </td>
              </template>
              <template #plan="{ item }">
                <td>
                  <template v-if="isTrial(item)">
                    <CIcon name="cipCube01" size="custom" :height="16" class="mr-1 c-icon--gradient"
                           style="vertical-align: text-top"/>
                    <span class="font-weight-medium" style="color: #4E4D64" v-translate>Free trial</span>
                  </template>
                </td>
              </template>

              <template #contract="{ item }">
                <td>
                  <template v-if="item.contract">
                    {{ item.contract.name}}
                  </template>
                </td>
              </template>

              <template #contract_manager="{ item }">
                <td>
                  <template v-if="item.contract_manager">
                    {{ item.contract_manager.name}}
                  </template>
                </td>
              </template>

              <template #actions="{ item }">
                <td class="py-2 text-center sticky-column">
                  <CButton
                      color="dark"
                      variant="outline"
                      class="btn-circle btn-circle--sm"
                      size="sm"
                      :to="routeToDetail(item)">
                    <CIcon name="cipArrowRight"/>
                  </CButton>
                </td>
              </template>
            </CDataTable>


          </CCardBody>

          <CCardFooter>
            <CPagination align="center" v-show="totalPages > 1" :active-page="activePage"
                         @update:activePage="onUpdatePage" :pages="totalPages"/>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import PicaServiceService from "../../services/pica_service.service"
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue"
import {mapActions, mapGetters} from "vuex";
import CreateNewEventModal from "@/domain/events/components/CreateNewEventModal.vue";
import WelcomeToPica from "@/domain/eventWizard/components/WelcomeToPica.vue";
import {layoutMixin} from "@/utils/mixins";
import EventTutorial from "@/domain/events/components/EventTutorial.vue";
import basePlanTags from "@/domain/core/constant/basePlanTags";

export default {
  name: 'EventList',
  mixins: [layoutMixin],
  components: {
    EventTutorial,
    WelcomeToPica,
    CreateNewEventModal,
    // Multiselect,
    ViewLoadingMessage
  },

  data() {
    return {
      picaServices: [],
      totalResults: 0,

      perPageItems: 20,
      loading: false,
      firstLoading: true,

      photoServices: [],
      photoServiceFilterSelected: null
    }
  },

  async mounted() {
    await this.fetchResults({page: this.activePage})
    this.firstLoading = false

    await this.$nextTick()
    this.handleTableScroll(this.$el.querySelector('.table-responsive'))

    //if (this.showWelcomeToPica) this.openCreateNewEvent()
    if (this.showWelcomeToPica) this.openTutorial()

  },

  computed: {
    ...mapGetters('events', ['columnFilterValue', 'activePage']),
    ...mapGetters('event', ['resortCategoryId']),
    ...mapGetters('login', ['userTimeZone']),
    totalPages() {
      return Math.ceil((this.totalResults) / this.perPageItems) || 1
    },
    showWelcomeToPica() {
      if (this.loading) return false
      if (this.totalResults > 0) return false
      if (!this.hasPerm('events.create')) return false
      if (this.columnFilterValue && Object.keys(this.columnFilterValue).length > 0) return false
      return true
    },
    filtersApplied() {
      const filters = Object.keys(this.columnFilterValue).reduce((acc, key) => {
        if (this.columnFilterValue[key] !== '') {
          acc[key] = this.columnFilterValue[key]
        }
        return acc
      }, {})
      return Object.keys(filters).length > 0 && !this.loading
    },
    fields() {
      const fields = []
      if (this.hasPerm('events.fields.id'))
        fields.push({
          key: 'id',
          label: 'ID',
          _style: 'width:5%;'
        })

      fields.push({
        key: 'event_name',
        label: this.$gettext('Name'),
      })

      fields.push({
        key: 'event_start_at',
        label: this.$gettext('Start on'),
        filter: false,
      })

      fields.push({
        key: 'status',
        label: this.$gettext('Status'),
        filter: false,
      })

      fields.push({
        key: 'plan',
        label: '',
        filter: false,
      })

      if (this.hasPerm('events.fields.contract'))
        fields.push({
          key: 'contract',
          label: this.$gettext('Contract'),
          filter: true,
        })

      if (this.hasPerm('events.fields.contract_manager'))
        fields.push({
          key: 'contract_manager',
          label: this.$gettext('Contract manager'),
          filter: true,
        })

      fields.push({
        key: 'actions',
        label: '',
        sorter: false,
        filter: false,
        _style: 'width:70px;',
        _classes: 'sticky-column',
      });
      const allKeys = Object.keys(fields);
      const filteredKeys = this.isInGroup('pica-managers') ? allKeys : allKeys.filter(key => key !== 'id');
      return filteredKeys.map(key => fields[key]);
    }
  },

  methods: {
    ...mapActions('events', [
      'updateColumnFilterValue',
      'updateActivePage'
    ]),
    getFields() {
      const allKeys = Object.keys(this.fieldsx);
      const filteredKeys = this.isInGroup('pica-managers') ? allKeys : allKeys.filter(key => key !== 'id');
      return filteredKeys.map(key => this.fieldsx[key]);
    },

    onUpdatePage(page) {
      this.updateActivePage(page)
      this.fetchResults({page})
    },

    fetchResults(params) {
      this.loading = true
      const p = {...this.columnFilterValue, ...params}
      return PicaServiceService.getPicaServices(p)
          .then((response) => {
            this.totalResults = response.data.count
            this.picaServices = response.data.results
            if (!params || !('page' in params)) this.updateActivePage(1);
            this.loading = false
          })
    },

    filterResults(params) {
      this.updateColumnFilterValue(params);
      this.fetchResults()
    },

    clearFilters() {
      this.updateColumnFilterValue({})
      this.fetchResults()
    },

    routeToDetail(item) {
      if (item['event']['is_draft'])
        return {name: 'eventWizard.date_and_name', params: {picaServiceId: item['id']}}
      return {name: 'eventWizard.overview', params: {picaServiceId: item['id']}}
    },
    resortIsClosed(item) {
      if (item.event.category_id === this.resortCategoryId) {
        const today = new Date()
        const eventStartAt = new Date(item.event.start_at)
        const eventEndAt = new Date(item.event.end_at)

        if (eventEndAt || (!eventStartAt && eventEndAt)) {
          return today > eventEndAt
        }

        if (eventStartAt && !eventEndAt) {
          return today < eventStartAt
        }

        if (!eventStartAt && !eventEndAt) {
          return true
        }
      }
      return false
    },

    hasDifferentTimezone(timezone) {
      if (!timezone) return false
      return timezone !== this.userTimeZone
    },

    openCreateNewEvent() {
      this.$refs.createNewEventModal.open()
    },
    openTutorial() {
      this.$refs.eventTutorial.open()
    },
    async onTutorialCompleted() {
      await this.delay(1500)
      this.$refs.welcomeToPica.showPopover()
    },
    isTrial(item) {
      if (!item.base_plan_tags) return false
      return item.base_plan_tags.includes(basePlanTags.trial)
    }
  },
  beforeDestroy() {
    if (!this.$route.path.includes('events')) {
      this.updateColumnFilterValue({})
      this.updateActivePage(1)
    }
  }
}
</script>
