<template>
  <div class="no-events d-flex justify-content-center align-items-center position-relative">
    <div>
      <CIcon name="cipStars06" size="custom" :height="48" class="mb-3 text-primary"/>
      <h6 class="font-weight-semi-bold mb-1" v-translate>No events available</h6>
      <div class="message" v-translate>Create your first event now</div>
      <div>
        <CButton @click.prevent="$emit('create')" color="primary">
          <CIcon name="cip-plus" style="vertical-align: sub"/>
          <translate>New event</translate>
        </CButton>
        <div class="no-events__popover mt-2">
          <transition name="fade-in-up">
            <div class="p-popover" v-show="popoverVisible">
              <h3 class="p-popover-header">{{ $pgettext('event.tooltip', 'Create your first event') }}
                <span class="p-closer" @click="closePopover">&times;</span></h3>
              <div class="p-popover-body">
                {{ $pgettext('event.tooltip', 'Create and customize it with the information you prefer') }}
              </div>
            </div>
          </transition>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeToPica",
  emit: ['create'],
  data() {
    return {
      popoverVisible: false
    }
  },

  methods: {
    showPopover() {
      this.popoverVisible = true
    },
    closePopover() {
      this.popoverVisible = false
    },
  }
}
</script>
<style>
.fade-in-up-enter-active{
  animation: fadeInUp 0.5s ease;
}
.fade-in-up-enter{
  opacity: 0;
  transform: translateY(20px);
}
</style>