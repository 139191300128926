<template>
  <div v-if="contracts">
    <div v-for="contract in contracts" :key="contract.id">
      <CLink
          @click="onClick(contract)"
          class="contract-item"
          :class="{'active': contract.id===selected}"
      >
        <div class="d-flex justify-content-between">
          <h3 class="contract-item__title m-0">
            {{ contract.name }} <span v-if="contract.qty_available < 100">({{contract.qty_available}} left)</span>
          </h3>
          <div class="align-self-center">{{contract.contract_manager}}</div>
          <CIcon name="cipCircleCheckFill" size="custom-size" width="18" height="18" class="ml-2 text-primary"
                 v-if="contract.id===selected"/>
          <CIcon name="cipCircle" size="custom-size" width="18" height="18" class="ml-2" v-else/>
        </div>
      </CLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractSelector",
  emits: ['update:value', 'input'],
  props: {
    contracts: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Number,
      default: null
    }
  },
  methods: {
    onClick(contract) {
      this.$emit('update:value', contract.id)
      this.$emit('input', contract)
    }
  }
}
</script>