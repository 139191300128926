<template>
  <CModal :show.sync="show" centered :close-on-backdrop="false" class="modal-tutorial">
    <template #header-wrapper>
      <div class="d-none"></div>
    </template>
    <div>
      <SwiperSlider
          ref="swiper"
          :slides-per-view="1"
          :slides-per-group="1"
          :simulate-touch="false"
          :has-pagination="true"
          @slide-change="onSlideChange">
        <template>
          <div v-for="slide in slides" :key="slide.index" class="swiper-slide">
            <div class="text-center">
              <CImg :src="slide.image" class="img w-100" fluid/>
              <div class="text">
                <h3 class="title">{{ slide.title }}</h3>
                <p class="descr" v-html="slide.description"></p>
              </div>
            </div>
          </div>
        </template>
      </SwiperSlider>
    </div>
    <template #footer>
      <div class="d-flex w-100">
        <div class="flex-grow-1 mr-1">
          <CButton block @click="close"
                   color="secondary"
                   v-translate translate-context="event_wizard.tutorial">Skip</CButton>
        </div>
        <div class="flex-grow-1 text-center ml-1">
          <CButton block
                   @click="onNext"
                   color="primary">
            <span v-translate translate-context="event_wizard.tutorial" v-if="tutorialIsCompleted">Let's go!</span>
            <span v-translate translate-context="event_wizard.tutorial" v-else>Next</span>
          </CButton>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>

import SwiperSlider from "@/domain/core/components/SwiperSlider.vue";

export default {
  name: "EventTutorial",
  components: {SwiperSlider},
  emits: ['completed'],
  data() {
    return {
      show: false,
      tutorialIsCompleted: false
    }
  },
  computed: {
    slides() {
      const slides = {
        1: {
          title: this.$pgettext('event_wizard.tutorial', 'We welcome you to the dashboard'),
          description: this.$pgettext('event_wizard.tutorial', 'We are happy to have you on board. Create and activate your event.<br>Here are some quick tips to help you get off on the right way.'),
          image: require('@/assets/images/event_tutorial/slide-1.png')
        },
        2: {
          title: this.$pgettext('event_wizard.tutorial', 'Customize your event'),
          description: this.$pgettext('event_wizard.tutorial', 'Create the digital photo album of your event, apply logo and title and add description, hashtags and emoticons.<br>Complete by entering the date.'),
          image: require('@/assets/images/event_tutorial/slide-2.png')
        },
        3: {
          title: this.$pgettext('event_wizard.tutorial', 'Announce your event'),
          description: this.$pgettext('event_wizard.tutorial', 'We provide you with the direct link to the album to send to all participants and a QR code to create cards, flyers or promotional displays'),
          image: require('@/assets/images/event_tutorial/slide-3.png')
        },
        4: {
          title: this.$pgettext('event_wizard.tutorial', 'Upload and deliver photos'),
          description: this.$pgettext('event_wizard.tutorial', 'Drag or select the folder and upload all the photos of the event, they will immediately be available and consultable by guests on smartphones.'),
          image: require('@/assets/images/event_tutorial/slide-4.png')
        },
        5: {
          title: this.$pgettext('event_wizard.tutorial', 'Track your event'),
          description: this.$pgettext('event_wizard.tutorial', 'Find out how many people saw their album, the average number of photos per attendee, photos downloaded and shares.'),
          image: require('@/assets/images/event_tutorial/slide-5.png')
        }
      }
      return Object.keys(slides).map(key => {
        return {
          index: key,
          ...slides[key]
        }
      })
    }
  },
  methods: {
    open() {
      this.show = true
      this.tutorialIsCompleted = false
      this.$refs.swiper.instance.slideTo(0)
    },
    close() {
      this.show = false
      this.$emit('completed')
    },
    onNext() {
      if (this.tutorialIsCompleted) {
        this.show = false
        this.$emit('completed')
        return
      }
      this.$refs.swiper.instance.slideNext()
    },
    onSlideChange() {
      if (this.$refs.swiper.instance.isEnd) {
        this.tutorialIsCompleted = true
      }
    }
  }
}
</script>
