<template>
  <div v-if="region && basePlans">
    <PTabs pills class="plan-categories"
           :tabs="categoryTabs"
           :selected="category"
           align-left
           @select="onSelectCategory"
           v-if="categoryTabs.length > 1"/>

    <div class="font-weight-semi-bold mb-3" v-if="category">{{category}}</div>

    <BasePlanSwitcher :plan="chosenPlan"
                      :currency="region.currency"
                      :has-next="chosenPlan.id !== basePlans[basePlans.length - 1].id"
                      :has-prev="chosenPlan.id !== basePlans[0].id"
                      @change="selectPlan" class="mb-4" v-if="chosenPlan"/>

    <div class="plan-help">
      <CIcon name="cipBriefcase01" size="custom-size" with="20" height="20" class="mr-2 my-2 text-primary"/>
      <div>
        <span v-translate translate-context="event.modal.create_new_event.help">
          Do you have special needs? Request a <b>customized solution</b> directly from our team
        </span>
      </div>
      <CLink target="_blank"
             :href="$pgettext('event.modal.create_new_event.contact_us', 'https://business.getpica.com/request-demo/')"
             class="font-weight-semi-bold mt-2 d-inline-block">
        <translate>Contact us</translate>
        <CIcon name="cipExternalLink" size="custom-size" with="16" height="16" class="ml-1"/>
      </CLink>
    </div>

  </div>
</template>

<script>
import PTabs from "@/domain/core/components/PTabs.vue";
import BasePlanSwitcher from "@/domain/events/components/BasePlanSwitcher.vue";

export default {
  name: "BasePlanSelector",
  components: {BasePlanSwitcher, PTabs},
  emits: ['update:value', 'input'],
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    region: {
      type: Object,
      required: true
    },
    selected: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      category: null,
      chosenPlan: null
    }
  },
  mounted() {
    for (const category of this.categories) {
      if (this.selected && category.base_plans.find(plan => plan.id === this.selected)) {
        this.category = category.name;
        return;
      }
    }
    if (!this.category) {
      this.category = this.categories[0].name;
    }

    if (this.selected) {
      this.selectPlan(this.selected, 0)
    } else {
      let defaultPlan = this.basePlans.find(plan => plan.is_default)
      if (defaultPlan)
        this.selectPlan(defaultPlan.id, 0)
      else
        this.selectPlan(this.basePlans[0].id, 0)
    }
  },
  computed: {
    categoryTabs() {
      return this.categories.map(category => ({
        name: category.name,
        label: category.name,
      }))
    },
    basePlans() {
      if (!this.category) return []
      const category = this.categories.find(category => category.name === this.category)
      return [...category.base_plans].sort((a, b) => a.price - b.price)
    },
  },
  methods: {
    onSelectCategory(name) {
      this.category = name
    },
    selectPlan(planId, step) {
      const index = this.basePlans.findIndex(plan => plan.id === planId)
      this.chosenPlan = this.basePlans[index + step]

      this.$emit('update:value', this.chosenPlan.id)
      this.$emit('input', this.chosenPlan)
    }
  }
}
</script>